@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  width: calc(100% - 48px);
  min-width: 100%;
  padding: 24px 0px 24px 0px;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    padding: 50px 0px 50px 0px;
    max-width: var(--contentMaxWidthPages);
  }
}

.container {
  max-width: var(--contentMaxWidthPages);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 14px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    width: calc(100% - 72px);
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0;
  }
}

.title {
  color: var(--colorTextBlack);
  font-weight: var(--fontWeightBold);
  font-size: 28px;
  line-height: 40px;
  max-width: 234px;
  width: 80%;
  text-align: center;

  @media (--viewportMedium) {
    width: 40%;
    font-size: 50px;
    line-height: 60px;
    max-width: 310px;
    text-align: left;
  }
}

.collapseContainer {
  padding: 0 30px;
  width: 100%;
  overflow: hidden;

  @media (--viewportMedium) {
    width: 60%;
    padding: 0 16px;
  }
}

.collapseList {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.collapseList li {
  display: flex;
  align-items: center;
  align-items: flex-start;
  gap: 12px;
  border-bottom: 1px solid var(--colorGrey40);
  cursor: pointer;

  @media (--viewportMedium) {
    gap: 16px;
    border-bottom: none;
  }
}

.collapseList li button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.collapseList li button svg {
  width: 16px;
  height: 16px;
  transition: transform 0.3s ease;
}

.collapseList li button:focus {
  outline: none;
}

.collapseListContentDiv {
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.collapseList li.collapsed .collapseListContentDiv {
  max-height: 830px;
}

.question {
  margin-top: 0;
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  color: var(--colorTextBlack);
  line-height: 20px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.answer {
  color: var(--colorTextBlack);
  font-size: 15px;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
  margin: 0;
}

.rotated {
  transform: rotate(-180deg);
}

.disclaimerText {
  color: black;
  text-align: center;
}
