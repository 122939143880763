@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  max-height: 700px;
  overflow-y: auto;
}

.reviewItem {
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}

.review {
  display: flex;
  flex-direction: row;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
}

.avatar {
  flex-shrink: 0;
  margin-right: 22px;
}

.reviewContent {
  composes: textSmall from global;
  font-style: italic;
  margin: 0;
  font-size: 15px;
  font-weight: var(--fontWeightRegular);
  line-height: 26px;
}

.reviewInfo {
  composes: h5 from global;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--colorGrey300);
  font-weight: 300;

  @media (--viewportMedium) {
    margin-top: 9px;
    line-height: 18px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: none;

  @media (--viewportMedium) {
    display: inline-flex;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}
