.root {
}

.inputError {
  border: var(--borderErrorField);
}
.legendDescription {
  font-size: 11px;
  font-weight: 400;
  color:#7C98B6 ;
}