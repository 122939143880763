@import '../../styles/customMediaQueries.css';

.root {
  fill: var(--colorGrey100);
  width: 18px;
  @media (--viewportMedium) {
    width:auto;
  }
}

.filled {
  fill: #fbbb00;
  width: 18px;
  @media (--viewportMedium) {
    width:auto;
  }
}
