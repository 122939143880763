@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 6px;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 0;
}

.checkIcon {
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: 24px;
}

.labelWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  @media (--viewportMedium) {
    border: 1px solid #0094ff4d;
    border-radius: 8px;
    padding: 0 6px;
  }

}

.selectedLabel,
.notSelectedLabel {
  composes: marketplaceBodyFontStyles from global;
  line-height: 24px;
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightRegular);
  padding-top: 0;
  padding-bottom: 0;
}

.notSelectedLabel {
  color: var(--colorGrey300);
  position: relative;
  text-decoration: line-through;
  font-weight: var(--fontWeightRegular);
  margin-left: 6px;
}
.externalLink {
  color: var(--colorTextBlack);
  &:hover {
    color: #62be78;
    text-decoration: none;
  }
}
