.root {
  stroke: var(--colorFail);
  fill: var(--colorFail);
  height: 24px;
  width: 24px;
}
.small {
  width: 16px;
  height: 16px;
}
.large {
  height: 30px;
  width: 30px;
}
