@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  width: 100%;
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
    max-width: 1056px;
  }
}

.title {
  line-height: 1.4;
  font-size: 40px;
  font-weight: var(--fontWeightBold);
  text-align: center;
  color: var(--colorTextBlack);
}

.warningContainer {
  padding: 0 10px;
}

.warningContainer p {
  text-align: center;
  color: var(--colorGrey700);
  font-weight: var(--fontWeightRegular);
}

.warningContainer hr {
  border-color: var(--colorBlueDark);
}

h5 {
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  text-align: center;
  color: var(--colorTextBlack);
  margin-bottom: 6px;
  line-height: 1.1;
}

a {
  color: var(--colorBlueButton);
  margin-left: 4px;
}
