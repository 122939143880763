@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;

    & .navLeft {
      display: flex;
    }

    & .navRight {
      display: flex;
    }
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100);
  /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: initial;
  bottom: 0px;
  background: #00000066;
  padding: 0px 10px 2px 10px;
  min-height: 114px;
  overflow-y: hidden;
}

.price {
  /* Layout */
  display: flex;
  position: absolute;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 0;
  right: 10px;
  top: 10px;
  padding: 0 16px;
  color: #0494ff;
  background-color: #e7f1f7;
  border-radius: 36.5px;
  height: 34px;
  align-items: center;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);
  font-size: 13px;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  color: #0494ff;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);
  font-size: 13px;
  color: #0494ff;
  display: inline-flex;
  align-items: center;
  margin-left: 2px;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  position: relative;
}

.title {
  /* Font */
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  color: var(--colorWhite);
  margin: 0;
  line-height: 28px;
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightLight);
  color: rgba(255, 255, 255, 0.7);
  padding-top: 4px;
  padding-bottom: 0px;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.favoriteButton {
  border: 0;
  padding: 0;
  cursor: pointer;
  float: right;
  right: 0;
  position: absolute;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--colorGrey700);
    }
  }
}

.customIcon {
  width: 24px;
  height: 24px;
  stroke: #fff;
  margin-right: 10px;
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  max-width: 100%;
  max-height: 100%;
}

.navLeft,
.navRight {
  position: absolute;
  width: 40px;
  height: calc(100% + 12px);
  bottom: 0;
  border: 0;
  padding: 0;
  z-index: 1;
  /* center content */
  display: none;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 0;
  top: -12px;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;
  top: -12px;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);

  @media (--viewportLarge) {
    /* center content */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }

  @media (--viewportXLarge) {
    width: 26px;
    height: 26px;
  }
}

.navArrowWrapper svg {
  @media (--viewportLarge) {
    width: 8px;
    height: 8px;
  }
}

.campingAvailable {
  background-color: #0094ff !important;
  left: -9px !important;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 26px;
  opacity: 1;
  padding: 0 0 0 13px;
  position: absolute;
  text-shadow: 1px 0 0 rgba(115, 64, 38, 0.6);
  top: 14px;
  z-index: 9 !important;
}

.campingAvailable::before {
  border-style: solid;
  content: '';
  height: 0;
  width: 0;
  border-width: 5px;
  left: 0;
  position: absolute;
  top: 100%;
  border-color: #0094ff #0094ff transparent transparent !important;
}

.campingAvailable::after {
  border-style: solid;
  content: '';
  height: 0;
  width: 0;
  border-width: 13px !important;
  left: 100%;
  position: absolute;
  top: 0;
  border-color: #0094ff transparent #0094ff #0094ff !important;
}

.newProperty {
  color: rgb(0, 148, 255);
  font-size: 13px;
  margin: 0;
  padding: 0;
}

.lodgingAvailable {
  background-color: #0094ff !important;
  left: -9px !important;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 26px;
  opacity: 1;
  padding: 0 0 0 13px;
  position: absolute;
  text-shadow: 1px 0 0 rgba(115, 64, 38, 0.6);
  top: 14px;
  z-index: 9 !important;
}

.lodgingAvailable::before {
  border-style: solid;
  content: '';
  height: 0;
  width: 0;
  border-width: 5px;
  left: 0;
  position: absolute;
  top: 100%;
  border-color: #0094ff #0094ff transparent transparent !important;
}

.lodgingAvailable::after {
  border-style: solid;
  content: '';
  height: 0;
  width: 0;
  border-width: 13px !important;
  left: 100%;
  position: absolute;
  top: 0;
  border-color: #0094ff transparent #0094ff #0094ff !important;
}

.authorInfoAddressTitle {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightLight);
  color: var(--colorWhite);
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 4px;
  padding-bottom: 0px;
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.reviewsContainer svg {
  width: 13px !important;
}

.reviewsBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.reviewsBox span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
  margin: 0;
  padding: 0;
}
